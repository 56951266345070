<template>
    <section class="sub-header">
        <div class="sub-header-content mx-auto flex justify-center">
            <router-link 
            :to="{name:'Credits'}" 
            class=""><p class="text-white text-sm font-semibold">{{ $t('header.subHeader') }}</p>
            </router-link>
        </div>
    </section>
</template>

<script setup>
</script>