import axios from 'axios';
import getToken from './getToken';

/**
 * Composable that subscribe a customer to get nofitication once a coach goes online
 */
const requestNotification = (() => {

  axios.interceptors.request.use (
    config => {
      const token = getToken();
      config.headers['Content-Type'] = 'application/json';
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  )

  /**
   * Pepare the payload for Email Subscription
   * @param coachData (object) | Coach Object
   * @param customerData (object) | Customer Object
  */
  const processRequest = async (coachData, customerData) => {
  
    // Payload required by BE
    var payload = {
      "coach": coachData.coach.coachProfileName,
      "email": customerData.email
    }

    // Subscribe customer to get notified once the coach goes online
    const response = await subscribe('subscribe-to-coach', payload);
    
    return response;
  }
  
  /**
   * Subscribe a customer email to get notified once the coach gets online
   * @param action (string) | EndPoint provided by BE
   * @param payload (object) | containers Coach name and customer email
   */
  const subscribe = async (action, payload) => {

    // TODO Remove Bogdan URL and uncomment below once STG API is updated
    // let api =  process.env.VUE_APP_URL + action;
    let api = 'https://5r5xmx11ee.execute-api.eu-west-1.amazonaws.com/bogdan/' + action;

    const response = await axios.post(api, payload);

    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    if(response.status !== 200) {
      throw new Error('no available data')
    }
  }

  return { 
    processRequest,
    subscribe
}

})();

export default { 
  processRequest: requestNotification.processRequest,
  subscribe: requestNotification.subscribe
}