<template>
  <div class="coach card">
    <div class="card-header loader">
      <div class="loading-gradient"/>
      
    </div>
    <div class="card-body">
      <div class="coach-pic loading-gradient"/>
      <div class="coach-details">
        <h6 class="coach-title loading-gradient">&nbsp;</h6>
        <ul class="coach-specials loader">
            <li v-for="i in 2" :key="i">
              <span class="loading-gradient"/>
            </li>
          </ul>

      </div>
    </div>
  </div>
</template>
