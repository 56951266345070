import { ref } from 'vue';
import axios from 'axios';

const getCollection = (doc) => {

  const error = ref(null);

  const get = async () => {
    let api =  process.env.VUE_APP_API_URL + doc;
    try{
      const response = await axios.get(api);

      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
  
      if(response.status !== 200) {
        error.value = 'no available data'
        throw new Error(error.value);
      }
    }
    catch(err){
      error.value = err.message;
    }
    
  }

  return { error, get }
}

export default getCollection