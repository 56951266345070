<template>
    <input v-model="searchText" @input="startSearch" :placeholder="placeholder ? placeholder : 'Search'" 
    class="block w-full rounded border-0 p-3 mb-5"/>
</template>
<script setup>
import { ref } from 'vue';
const props = defineProps({ placeholder: String });
const emit = defineEmits();
const searchText = ref('');
const startSearch = () => {
    if(searchText.value.length > 2) 
        emit('search', searchText.value.toLowerCase());
    else if (searchText.value == '')
        emit('search', '');
};
</script>
  