<template>
  <header id="navbar" ref="navbar">
    <nav>
      <div class="container">
        <router-link :to="{name:'Home'}" class="logo navbar-brand" >
          <!-- logo  -->
          <slot name="logo">
            <!-- Default slot content  -->
            <h3>domain</h3>
          </slot>
        </router-link>

        <button data-collapse-toggle="navbar-default"
          @click="toggleMenu"
          type="button" 
          aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>

        <div class="hidden" id="navbarCollapse">
          <ul ref="nav">
                  <li class="nav-item">
                    <router-link :to="{name:'Home'}" class="nav-link" aria-current="page"  @click="toggleMenu">{{ $t("header.home") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{name:'About'}" class="nav-link"  @click="toggleMenu">{{ $t("header.about") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{name:'Advice'}" class="nav-link"  @click="toggleMenu">{{ $t("header.advice") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{name:'Readers'}" class="nav-link"  @click="toggleMenu">{{ $t("header.readers") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{name:'FAQ'}" class="nav-link"  @click="toggleMenu">{{ $t("header.faq") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{name:'Contact'}" class="nav-link"  @click="toggleMenu">{{ $t("header.contact") }}</router-link>
                  </li>
                  <!-- reactive nav items-->
                  <template v-if="authStore.isAuthenticated">
                    <li class="nav-item">
                      <router-link :to="{name:'MyAccount'}" class="nav-link"  @click="toggleMenu">{{ $t("header.myAccount") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{name:'Credits'}" class="btn btn-sm btn-dark"  @click="toggleMenu">{{ $t("header.getCredits") }}</router-link>
                    </li>
                  </template>

                  <template v-else>
                    <li class="nav-item">
                      <router-link :to="{name:'Login'}" class="nav-link"  @click="toggleMenu">{{ $t("header.login") }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{name:'Signup'}" class="btn btn-sm btn-dark"  @click="toggleMenu">{{ $t("header.getStarted") }}</router-link>
                    </li>
                  </template>
          </ul>
        </div>
      </div>
    </nav>

    <Subheader v-if="!stripeStore.subscription && currentRouteInfo !== 'Credits' && currentRouteInfo !== 'Home' && currentRouteInfo !== 'Checkout' && currentRouteInfo !== 'Success'"/>
  </header>

</template>

<script setup>
  import { onMounted, ref, watchEffect, watch } from "vue"
  import { useAuthStore, useStripeStore } from '@core/stores';
  import { useRoute } from "vue-router";
  import Subheader from "./Subheader.vue";

  const navbar = ref();
  const props = defineProps(['logo']);
  const stripeStore = useStripeStore();
  const route = useRoute();
  const currentRouteInfo = ref({})

  const getLogoUrl = ()=> {
    return new URL(props.logo, import.meta.url)
  }
  onMounted(() => {
      window.onscroll = ()=> { onwindowScroll(); }
  })

  //on scroll make navbar opaque
  const onwindowScroll = () => {
    //get difference of padding top of first section and height of navbar
    const navbarHeight = navbar.value.clientHeight;
    const firstSection = document.querySelector('section');
    const paddingTop = window.getComputedStyle(firstSection, null).getPropertyValue('padding-top');
    const scrollYDiff = parseInt(paddingTop) - navbarHeight;

    if (
      document.body.scrollTop > scrollYDiff ||
      document.documentElement.scrollTop > scrollYDiff
    ) {
      navbar.value.classList.add("fixed-top");
    } else {
      navbar.value.classList.remove("fixed-top");
    }
  }

  // toggle menu for smaller screens
  const toggleMenu = () => {
    document.getElementById("navbarCollapse").classList.toggle("hidden");
  }

  const authStore = useAuthStore();

  // Watch for changes in the route parameters
  watch(() => route.name, (newParams, oldParams) => {
      currentRouteInfo.value = newParams;
    },
    { immediate: true } // Optional: run the watcher immediately on setup
  )
</script>


