import { defineStore } from 'pinia';
import { axiosRequest } from '@core/composables/helpers/axios-requests';
import { useStorage } from "vue3-storage";
import { decryptThis } from '@core/composables/utilities';
const storage = useStorage();

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: storage.getStorageSync("customerInfo"),
        err: null
    }),
    actions: {
        async getUser(token){
            const {sub} = decryptThis(token);
            const userInfo = await axiosRequest.get('/customers/' + encodeURIComponent(sub));
            storage.setStorageSync("customerInfo", userInfo.data);
            this.user = userInfo.data;
        },
        clearErrMsg(){
            this.err = null;
        }
    },
    getters: {
        errMsg: state => state.err,
        userInfo: state => state.user?.profile
    },
});
