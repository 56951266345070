<template>
  <div>
  <section class="hero">
    <div class="container mx-auto">
      <h1 class="sm:w-1/2 mb-5">{{ $t("hero.heading") }}</h1>
      <p class="text-lg mb-5">{{ $t("hero.text") }}</p>
      <div class="flex gap-3 justify-start">
        <router-link :to="{name: 'Signup'}" class="btn btn-accent !text-dark-600 btn-lg me-md-1"  v-if="!authStore.isAuthenticated" >{{ $t("hero.signUp") }}</router-link>
        <router-link :to="{name: 'Readers'}" class="btn btn-accent !text-dark-600 btn-lg me-md-1"  v-else>{{ $t("hero.readers") }}</router-link>
      </div>

      <div class="hero-bar">
        <h4>{{ $t("hero.reviews") }}</h4>
        <div class="flex gap-2 items-center">
          <img src="../assets/img/stars.png" class="stars" :alt='$t("hero.ratings")' height="15" loading="lazy" />
          <span>{{ $t("hero.ratings") }}</span>
        </div>
      </div>
    </div>
    <svg viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto" class="waves">
      <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
      </defs>
      <g class="parallax">
      <use href="#gentle-wave" x="10" y="0" fill="rgba(255,255,255,0.32"></use>
      <use href="#gentle-wave" x="30" y="1" fill="rgba(255,255,255,0.27)"></use>
      <use href="#gentle-wave" x="60" y="3" fill="rgba(255,255,255,0.12)"></use>
      <use href="#gentle-wave" x="90" y="7" fill="#fff"></use>
      </g>
    </svg>
  </section>
  </div>
</template>
<script setup>
import { useAuthStore } from '@core/stores';
const authStore = useAuthStore();

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

