<template>
  <TransitionRoot as="template" :show="open" >
      <Dialog as="div" class="modal" @close="closeModal">
        <TransitionChild as="template" enter="modal-bg-enter" enter-from="modal-bg-enter-from" enter-to="modal-bg-enter-to" leave="modal-bg-leave" leave-from="modal-bg-leave-from" leave-to="modal-bg-leave-to">
          <div class="modal-bg" />
        </TransitionChild>

        <div class="modal-wrap">
          <div class="modal-flex" :class="{'flex-center' : !slots.modalBtns}"> <!-- align center in mobile if there's no buttons -->
            <TransitionChild as="template" enter="modal-panel-enter" enter-from="modal-panel-enter-from" enter-to="modal-panel-enter-to" leave="modal-panel-leave" leave-from="modal-panel-leave-from" leave-to="modal-panel-leave-to">
              <DialogPanel class="modal-panel">
                <div class="modal-body">
                  <div class="sm:items-start"
                    :class="{'sm:flex' : slots.modalIcon}">
                    <div v-if="slots.modalIcon" class="modal-icon">
                      <slot name="modalIcon">
                        <div>
                          <ExclamationTriangleIcon class="default"/>
                        </div>
                      </slot>
                    </div>
                    <div class="modal-title-wrap"
                      :class="{'sm:ml-4' : slots.modalIcon}">
                      <DialogTitle v-if="slots.modalTitle" class="modal-title">
                        <slot name="modalTitle">
                        </slot>
                      </DialogTitle>
                      <div v-if="slots.modalContent" class="modal-texts">
                        <slot name="modalContent">
                        </slot>
                      </div>
                    </div>
                  </div>
                  <div v-if="slots.modalBtns" class="modal-btns">
                    <slot name="modalBtns">
                    </slot>
                  </div>
                  <div v-else>
                    <button style="opacity:0; pointer-events:none">Focus Trap</button>
                  </div>
                </div>
                <div v-if="slots.modalFooter" class="modal-footer">
                  <slot name="modalFooter">
                  </slot>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
  </TransitionRoot> 


</template>

<script setup>
import { useSlots } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {ExclamationTriangleIcon} from '@heroicons/vue/24/outline'

const props = defineProps({
  open: {
    type: Boolean,
    default: true,
  }
});

const slots = useSlots()

// to allow user to close modal on backdrop click
// use @close="open = false" in parent component
const emit = defineEmits(['close'])
const closeModal = ()=>{
  emit('close');
}
</script>

<style scoped>
  .flex-center{
    align-items: center;
  }
</style>
