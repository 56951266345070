import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home'
import NotFound from  '@/views/NotFound.vue'
import Coaches from '@/views/Coaches.vue'
import Coach from '@/views/Coach.vue'

import authGuard from '@core/composables/authGuard'
import pageTitles from './pageTitles'
// TODO: create lazy loading routes
// ref https://router.vuejs.org/guide/advanced/lazy-loading.html

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/get-started',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/Auth/Signup.vue')
  },
  {
    path: '/credits',
    name: 'Credits',
    component: () => import(/* webpackChunkName: "credits" */ '@/views/Credits.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
  {
    path: '/advice',
    name: 'Advice',
    component: () => import(/* webpackChunkName: "articles" */ '@/views/Advice.vue'),
    meta: { title: pageTitles.advice },
  },
  {
    path: '/advice/:article',
    name: 'AdviceSingle',
    component: () => import(/* webpackChunkName: "articles" */ '@/views/AdviceSingle.vue')
  },
  {
    path: '/advice/tag/:tag',
    name: 'AdviceTag',
    component: () => import(/* webpackChunkName: "articles" */ '@/views/AdviceTag.vue')
  },
  {
    path: '/explore',
    name: 'Explore',
    component: () => import(/* webpackChunkName: "readings" */ '@/views/Readings.vue'),
    meta: { title: pageTitles.readings },
    children:[{
      path: '/readings/:type', 
      name: 'Readings', 
      component: () => import(/* webpackChunkName: "readings" */ '@/views/Readings.vue')
    }]
  },
  {
    path: '/readers',
    name: 'Readers',
    component: Coaches
  },
  {
    path:'/readers/:name',
    name: 'Reader',
    component: Coach
  },
  {
    path: '/select-readers',
    name: 'SelectReaders',
    component: Coaches
  },
  {
    path:'/select-readers/:name',
    name: 'SelectReader',
    component: Coach
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '@/views/FAQ.vue')
  },
  {
    path:'/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/Login')
  },
  {
    path:'/reset-password',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/ResetPassword')
  },
  {
    path:'/account',
    name: 'MyAccount',
    component: () => import(/* webpackChunkName: "myaccount" */ '@/views/MyAccountCol'),
    beforeEnter: authGuard
  },
  {
    path:'/chat/:name',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/Chat')
  },
  {
    path:'/terms-and-conditions',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/Terms')
  },
  {
    path:'/privacy-policy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/Privacy')
  },
  {
    path:'/cookies-notice',
    name: 'Cookies',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/Cookies')
  },
  {
    path:'/payment-policy',
    name: 'Payment Policy',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/Payment')
  },
  {
    path:'/refund-policy',
    name: 'Refund Policy',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/Refund')
  },
  {
    path:'/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/Payment/Checkout')
  },
  {
    path:'/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/Payment/Success')
  },
  {
    path:'/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/Payment/Error')
  },
  { 
    path: "/start", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/Start')
  },
  { 
    path: "/start-chat", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/StartChat')
  },
  { 
    path: "/lp3", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/LP3')
  },
  { 
    path: "/lp4", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/LP4')
  },
  { 
    path: "/lp5", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/LP5')
  },
  { 
    path: "/lp6", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/LP6')
  },
  { 
    path: "/lp9", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/LP9')
  },
  { 
    path: "/lp10", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/LP10')
  },
  { 
    path: "/lp11", 
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/LP/LP11')
  },
  { 
    path: "/:catchAll(.*)", 
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    // if (to.hash) {
    //   return {
    //     el: to.hash,
    //     behavior: 'smooth',
    //   };
    // }
    // return savedPosition || { top: 0 };
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          } else {
            console.warn(`Element with selector "${to.hash}" not found.`);
          }
          resolve();
        }, 100); // Adjust the delay as needed
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes
})

const SITE_TITLE = 'Arcanera';
router.afterEach((to, from) => {
    document.title = to.meta.title ? `${SITE_TITLE} - ${to.meta.title}` : SITE_TITLE;
});

export default router
