import axios from 'axios';
import { useAuthStore } from '@core/stores';
import getToken from '@core/composables/getToken';

const userAuth = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true // Allow sending cookies with requests
});

export const axiosRequest = {
    post: postData(),
    get: getData()
};

axios.interceptors.request.use(
    config => {
    let apiDomain = new URL(config.url).hostname.split('.'); // Use hostname instead of searchParams.get('domain')

    // Check if the domain matches the specified domains
    if (apiDomain[1] === 'arcanera' || apiDomain[1] === 'thelmcoach') {

        // Get token and user language
        const token = getToken();
        const userLanguage = sessionStorage.getItem("X-User-Language") || 'en';  // Get language from session storage or default to 'en'
        
        // Set headers
        config.headers['Content-Type'] = 'application/json';
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        
        // Append X-User-Language header
        config.headers['X-User-Language'] = userLanguage;
    } 

    return config; // Return the modified config
    },
        error => {
        return Promise.reject(error); // Handle errors
    }
);

// Intercept request if API returns an error/401 - redirect Users to Login page
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
        const authStore = useAuthStore();
        authStore.logout()
        }
        return Promise.reject(error.message);
    }
);

function postData() {
    return (url, body) => {
        const requestBody = body;
        return userAuth.post(url, requestBody).then(handleResponse).catch(handleError);
    }
}

function getData() {
    return (url) => {
        const requestOptions = {
            headers: authHeader(url)
        };
        return userAuth.get(url, requestOptions).then(handleResponse).catch(handleError);
    }
}

function handleError(error) {
    return error.response;
}    

function handleResponse(response) {
    return response;
}    

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { token } = useAuthStore();
    const isLoggedIn = !!token;
    if (isLoggedIn && url) {
        return { Authorization: `Bearer ${token}` };
    } 
}