import axios from 'axios';

/**
 * Validate coupons
 * @param {*} coupon 
 * @param {*} customerId 
 * @returns Object
 */
const validateCoupons = async (coupon, customerId) =>{
    let api = process.env.VUE_APP_API_URL + 'coupons/coupon';
    
    if (customerId) {
        try {
            const response = await axios.get(`${api}?coupon_code=${coupon}&customer_id=${customerId}`);
            return response.data;
        }
        catch(err){
            throw new Error(err);
        }
    }
    
}

export {
    validateCoupons
};