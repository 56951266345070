import { useStorage } from "vue3-storage";
import { watchEffect } from 'vue';

const storage = useStorage()

const isUserLogin = () => {
    let isLogin;
    watchEffect(() => {
        if(storage.getStorageSync('customerJWT'))
            isLogin = true;
        else
            isLogin = false;
    });
    return isLogin;
}

export { isUserLogin }