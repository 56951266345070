<template >
  <section class="w-full h-screen flex justify-center items-center" v-if="coachesLoaded && ltParam || showChatLoader">
    <div class="flex flex-col justify-center items-center">
      <Spinner class="mb-3 h-7 w-7 text-gray-300"/>
      <p class="flex text-gray-400"><UsersIcon  class="mr-2 h-6 w-6 text-primary-600"/>{{ $t("chat.chatLoadingRedirect") }}</p>
    </div>
  </section>
  <section class="coaches-wrap" v-if="!ltParam">
    <div class="container">
      <h2 class="section-header">{{ $t("coaches.coachesH") }}
        <div v-if="isLogin">
          <router-link :to="{ path: '/account', query: { hash: 'chatLogs' } }"
          class="btn btn-accent btn-sm m-2">
          {{ $t("coaches.coachesLogs") }}
          </router-link>
        </div>
      </h2>
    </div>
    <div id="coachList" class="container">
      <CoachList/>
    </div>
  </section>
</template>

<script setup>
import CoachList from "@core/components/CoachList.vue";
import { isUserLogin } from "@core/composables/isUserLogin.js";
import { onMounted, ref, watchEffect, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { decryptThis } from '@core/composables/utilities';
import { useUserStore,useAuthStore, useStripeStore } from '@core/stores';
import getToken from '@core/composables/getToken';
import { useStorage } from "vue3-storage";
import { getQryParams, shuffleList } from '@core/composables/utilities';
import getCoaches from '@core/composables/getCoaches';
import Spinner from '@core/components/utilities/Spinner.vue'
import { UsersIcon } from '@heroicons/vue/24/solid'

const isLogin = isUserLogin();

const router = useRouter();
const route = useRoute();
const storage = useStorage();

const userStore = useUserStore();
const authStore = useAuthStore();
const stripeStore = useStripeStore();

const ltParam = getQryParams().get('lt');
const coaches = ref([]);
const coachesLoaded = ref(false);

// Prevent User to redirect back to 'select-readers' page after signup
const readersStorage = storage.getStorageSync("select-readers");

// set showChatLoader to enable loader coming from a landing page
const showChatLoader = storage.getStorageSync("showChatLoader");

onMounted(async () => {
  // Check if User has an existing Subscription
  await stripeStore.getSubscriptions();
  if (ltParam) {
    try {
        coachesLoaded.value = true;
        decryptThis(ltParam);

        authStore.setToken(ltParam);
        await userStore.getUser(ltParam);

        // Redirects to a Random Reader coming from a Landing Page or Email
        const { get } = getCoaches();
        coaches.value = shuffleList(await get());

        if (coaches.value.length > 0) {
            coachesLoaded.value = false;
            router.push({ name: 'Chat', params: { name: coaches.value[0].coachProfileName } });
        }
    } catch (err) {
        coachesLoaded.value = false;
        authStore.logout();
        router.push({ name: 'Login' });
        throw new Error('Error processing ltParam', err);
    }
  }

  if (showChatLoader) {
      // Redirects to a Random Reader coming from a Landing Page or Email
      const { get } = getCoaches();
      coaches.value = shuffleList(await get());
      if (coaches.value.length > 0) {
        router.push({name: 'Chat', params: {name: coaches.value[0].coachProfileName}});
      }
    }

  getToken();

  const targetElId = router.currentRoute.value.query.hash;
  if (targetElId) {
    const targetEl = document.getElementById(targetElId);
    if (targetEl) {
      const offset = targetEl.getBoundingClientRect().top;
      const scrollOptions = {
        top: window.scrollY + offset - 50,
        behavior: 'smooth',
      };
      setTimeout(() => window.scrollTo(scrollOptions));
    }
  }
  
  // Moved here since login redirection is changed from MyAccount to Readers page
  if(sessionStorage.getItem('fbclid') && !sessionStorage.getItem('lead')) { 
      sessionStorage.setItem('lead', true);
      // FB Event: A prospect has been succesfully converted as a lead from FB as source
      fbq('track', 'Lead');
  }
  if(sessionStorage.getItem('ttclid') && !sessionStorage.getItem('lead')) { 
      sessionStorage.setItem('lead', true);
      // Tiktok Event: A prospect has been succesfully converted as a lead from TikTok as source
      ttq.track('CompleteRegistration');
  }
});

onBeforeMount(() => {
  watchEffect(async () => {
    if (!readersStorage) {
      router.push({name: 'Readers'});
    }
  })
})

onBeforeUnmount(() => {
  watchEffect(() => {
    if (readersStorage) {
      // remove 'select-readers' storage
      storage.removeStorageSync("select-readers");
    }
  })
})

</script>


