import { defineStore } from 'pinia';
import { useStorage } from "vue3-storage";
import router from '@/router';
import Stripe from 'stripe'
import {loadStripe} from '@stripe/stripe-js/pure';
import { initializeLanguage, getInitializedLang } from '@core/composables/utilities';

const storage = useStorage();
const stripe = new Stripe(process.env.VUE_APP_STRIPE_SECRET_KEY);
const successURL = window.location.origin + router.resolve({name: 'Success'}).href;

export const useStripeStore = defineStore({
    id: 'stripe',
    state: () => ({
        user: null,
        product: storage.getStorageSync("product") || null,
        discountCode: null,
        stripePayment: null,
        elements: null,
        customer: null,
        paymentMethod: null,
        hadSubscription: false,
        subscription: storage.getStorageSync("subscription") || null,
        subscriptionExpiry: storage.getStorageSync("subscriptionExpiry") || null,
        //Bank Redirect iDeal, Bankcontact
        paymentOption: null,
        stripeProductIds: '',
    }),
    actions: {
        setProduct(product) {
            if (product) {
                this.product = { ...product, discountCode: this.discountCode };
                storage.setStorageSync("product", this.product);
            } else {
                this.product = null;
                storage.removeStorageSync("product");
            }
        },

        setDiscountProduct(productPrice) {
            if (productPrice && this.product) {
                const discountPrice = {
                    priceId: productPrice.id,
                    price: (productPrice.unit_amount / 100).toFixed(2),
                    currency: productPrice.currency,
                    priceType: productPrice.nickname,
                    discountCode: productPrice.metadata.coupon_applied
                };

                // Spread discountPrice directly into the product object
                this.product = { ...this.product, ...discountPrice };
                storage.setStorageSync("product", this.product);
            } else {
                if (this.product) {
                    const { price, currency, priceType, ...rest } = this.product;
                    this.product = rest;
                    storage.setStorageSync("product", this.product);
                }
            }
        },

        setDiscountCode(code) {
            this.discountCode = code;
            if (this.product) {
                this.product.discountCode = code;
                storage.setStorageSync("product", this.product);
            }
        },

        resetDiscountCode() {
            if (this.product) {
                this.product.discountCode = '';
                this.discountCode = '';
                storage.setStorageSync('product', this.product);
            }
        },

        async loadStripePayment() {
            await initializeLanguage(); // Ensure language is initialized
            const locale = getInitializedLang(); // Get the initialized language
      
            this.stripePayment = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {
              locale,  // Use the initialized language
            });
      
            const paymentIntent = await this.getPaymentIntent();
            const clientSecret = paymentIntent.client_secret;
            const options = {
              layout: {
                type: 'tabs',
                defaultCollapsed: false,
                radios: false,
                spacedAccordionItems: true,
              },
            };
      
            // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
            this.elements = this.stripePayment.elements({ clientSecret });
      
            // Create and mount the Payment Element
            const paymentElement = this.elements.create('payment', options);
            paymentElement.mount('#payment-element');
      
            return new Promise((resolve) => {
              paymentElement.on('ready', function(event) {
                resolve(true);
              });
            });
        },

        async getPaymentIntent(){
            const customer = await this.getCustomer() || await this.createCustomer();
            const isUnlimited = this.product.metadata.isUnlimited === 'true';
            const payload = {
                customer: customer.id,
                
                amount: this.product.metadata.trial_price ? parseInt(this.product.metadata.trial_price.replace('.', '')) : parseInt(this.product.price.replace('.', '')), // replace with trial price to be set in product meta
                currency: this.product.currency,
                // In the latest version of the API, specifying the `automatic_payment_methods` parameter is optional because Stripe enables its functionality by default.
                // payment_method_types: ['ideal', 'card'],
                metadata :{
                    product: this.product.id,
                    credits: this.product.metadata.credits,
                    discountCode: this.product.discountCode,
                    priceType: this.product.priceType,
                    priceId: this.product.priceId,
                    isUnlimited: isUnlimited ? isUnlimited : ''
                },
            }
            if (this.paymentOption === 'card' && this.product.recurring) {
                payload.payment_method_types = ['card'];
                payload.setup_future_usage = 'off_session';
            } else if (this.paymentOption !== 'card') {
                payload.payment_method_types = [this.paymentOption]
            }else{
                // all other payment methods
                Object.assign(payload,{
                    setup_future_usage: 'off_session', //enables topup
                    automatic_payment_methods: {
                        enabled: true,
                    }
                })
            }

            //add coachName to metadata
            const coachInterrupted = storage.getStorageSync('coachInterrupted')
            if(coachInterrupted){
                payload.metadata.coachName = coachInterrupted;
            }

            const paymentIntent = await stripe.paymentIntents.create(payload);
            
            return paymentIntent;
        },

        async confirmPayment(){
            const {error} = await this.stripePayment.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements: this.elements,
                confirmParams: {
                    return_url: `${successURL}`, 
                },
            });
        
            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                console.error('Payment confirmation error:', error.message);
                return error;
            } else {
                // customer will be redirected to `return_url`. For some payment
                // methods like iDEAL, customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        },

        async retrievePaymentIntent(paymentIntentId){
            // by paymentIntentId
            return await stripe.paymentIntents.retrieve(paymentIntentId);

            // by clientSecret. This doesn't include metadata object
            // this.stripePayment = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
            // return await this.stripePayment.retrievePaymentIntent(clientSecret);
        },

        // Attached Payment Method if doesnt exist when buying a subscription
        async attachPaymentMethod(paymentMethodId, customerId){
            return await stripe.paymentMethods.attach(paymentMethodId, {
                customer: customerId,
            });
        },

        async getCustomer(){
            try {
                this.user = storage.getStorageSync("customerInfo");
                if (!this.user) return null;
        
                const customers = await stripe.customers.search({
                    query: `metadata['_id']:'${this.user.profile._id}'`,
                });
                if (customers.data.length === 0) {
                    return null;
                }
        
                const customer = customers.data[0];
                return customer;
            } catch (error) {
                console.error("Error retrieving customer:", error);
                return null; // Return null or handle the error as needed
            }
        },

        async createCustomer(){
            // reset for new customer
            this.hadSubscription = false;
            if(!this.user) return
            return await stripe.customers.create({
              name: this.user.profile._id,
              email: this.user.profile._id.split('|')[1],
              metadata : {
                "_id": this.user.profile._id
              }
            });
        },

        async getPaymentMethod(){
            const customer = await this.getCustomer();
            if(!customer)return

            const paymentMethods = await stripe.paymentMethods.list({
                customer: customer.id,
                // type: 'card', //switch to list only cards
            });

            this.paymentMethod = paymentMethods.data.length > 0 ? paymentMethods.data[0] : null;
            return this.paymentMethod
        },

        removePaymentMethod(){
            this.paymentMethod = null;
        },
    
        async oneClickPay(coachName){
            const customer = await this.getCustomer();
            await this.getPaymentMethod();
            if(!this.paymentMethod){
                return
            }
            const payload = {
                amount: parseInt(this.product.price.replace('.', '')),
                // payment_method_types: ['card', 'link', 'ideal', 'sepa_debit'], // https://dashboard.stripe.com/settings/payment_methods
                currency: this.product.currency,
                customer: customer.id,
                payment_method: this.paymentMethod.id,
                return_url: window.location.href,
                off_session: true,
                confirm: true,
                metadata :{
                    product: this.product.id,
                    credits : this.product.metadata.credits,
                    priceId : this.product.priceId,
                    coachName
                }
            }
           
            try {
                const paymentIntent = await stripe.paymentIntents.create(payload);
                return paymentIntent
            } catch (err) {
                // Error code will be authentication_required if authentication is needed
                console.error('Error code is: ', err.code);
                const paymentIntentRetrieved = await stripe.paymentIntents.retrieve(err.raw.payment_intent.id);
                console.error('PI retrieved: ', paymentIntentRetrieved.id);
                return false
            }
        },
        
        async createSubscription(lastPaymentIntent){
            const customer = await this.getCustomer();

            const payLoad = {
                customer: customer ? customer.id : lastPaymentIntent.customer,
                items: [
                    {
                        price: this.product.priceId, 
                    },
                ],
                metadata: {
                    "pi_ID": lastPaymentIntent.id,
                },
                /**
                 * Add a Default Payment Method to the subsription 
                 * base on the successful payment initiated by the
                 * customer during package purchase
                 */
                default_payment_method: lastPaymentIntent.payment_method
            }

            // trial period for new cust / no past subscriptions
            if(!this.hadSubscription){
                payLoad.trial_period_days = this.product.metadata.trial_period
            }

            const subscription = await stripe.subscriptions.create(payLoad).catch(error => {
                console.error('Error creating subscription:', error);
                return null;
            });
            this.subscription = subscription;
            
            if(subscription){
                storage.setStorageSync("subscription", subscription);
            }else{
                storage.removeStorageSync("subscription");
            }
        },
        async getSubscriptions() {
            this.customer = await this.getCustomer();
            if (!this.customer) return
            // Retrieve subscriptions with both 'active' and 'trialing' statuses
            const subscriptions = await stripe.subscriptions.list({
                customer: this.customer.id,
                status: 'all', // Change to 'all' to retrieve all statuses
            });

            if (subscriptions.data.length) {
                // Optionally filter for active or trialing subscriptions
                const subscriptionStatus = subscriptions.data.filter(subscription => 
                    subscription.status === 'active' || subscription.status === 'trialing'
                );
                if (subscriptionStatus.length) {
                    if (subscriptionStatus[0].cancel_at) {
                        storage.setStorageSync("subscriptionExpiry", subscriptionStatus[0].cancel_at);
                    }
                    storage.setStorageSync("subscription", subscriptionStatus[0]);
                    return subscriptionStatus[0];
                }
            }

            // No subscriptions found
            return null;
        },
        async getPastSubscriptions(){
            const customer = await this.getCustomer();
            if (!customer) return

            const subscriptions = await stripe.subscriptions.list({
                customer: customer.id,
                status: 'canceled',
            });
            if (subscriptions.data.length) return this.hadSubscription = true;
        },

        async cancelSubscription(subscriptionId) {
            try {
                // Update the subscription to cancel at the end of the period
                const response = await stripe.subscriptions.update(subscriptionId, {
                    cancel_at_period_end: true,
                });
        
                if (response.error) {
                    console.error('Error updating subscription:', response.error.message);
                    return { success: false, error: response.error.message };
                }
                storage.setStorageSync("subscriptionExpiry", response.cancel_at);
                return { success: true, data: response };
            } catch (error) {
                console.error('Unexpected error updating subscription:', error);
                return { success: false, error: error.message };
            }
        },

        clearSubscription() {
            this.subscription = null;
            this.subscriptionExpiry = null;
            storage.removeStorageSync("subscription");
            storage.removeStorageSync("subscriptionExpiry");
        },

        // Action to fetch Stripe products
        async fetchStripeProducts() {
            const params = { 
                limit: 99,
                active: true 
            };

            try {
                const response = await stripe.products.list(params);
                this.stripeProductIds = response.data.map(data => data.id);
            } catch (error) {
                console.error(error);
            }
        }
    },
    getters:{
        stripeProducts : () => {
            return process.env.NODE_ENV === 'production' ? 
                //live mode product id's
                [
                    /**
                     * Get id/s string from stripe dashboard of the product/s to be displayed
                     * or return empty array to display all
                     */
                ]
                : //test mode product id's
                [
                    
                ];
        }
    }
});
