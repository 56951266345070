<template>
  <div class="cards">
        <img src="../assets/img/cards.png" class="cards-set h-12" alt="cards" loading="lazy" height="25">
        <img src="../assets/img/ssl.png" class="ssl" alt="ssl" height="25" loading="lazy">
    </div>
</template>

<style lang="scss" scoped>
.cards{
    display: flex;
    &-set{
        max-width:80%;
    }
    img{
        object-fit:contain;
        flex-shrink:0;
    }
}
</style>

