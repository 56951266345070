import { defineStore } from 'pinia';
import { validateCoupons } from '@core/composables/getCoupons';

export const useCouponStore = defineStore({
    id: 'coupons',
    state: () => ({
        isValid: null,
        err: null
    }),
    actions: {
        setCoupon(data) {
            this.isValid = data;
        },
    },
    getters: {
        errMsg: state => state.err,
    },
});
