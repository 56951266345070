import { useStorage } from "vue3-storage";
import { useAuthStore } from '@core/stores';
import { decryptThis } from '@core/composables/utilities';

const storage = useStorage();

const getToken = () => {
    let token = null;
    const authStore = useAuthStore();

    if (authStore.isAuthenticated) {
        token = storage.getStorageSync('customerJWT');
    }
    checkTokenExpiry(token);
    return token;
}

// Monitor JWT expiration
const checkTokenExpiry = (token) => {
  const authStore = useAuthStore();
  
  if (!token) {
    // User is not logged in
    // Force Logout Customer
    // authStore.logout();
    return;
  }

  const tokenData = decryptThis(token); // Decode JWT payload
  const currentTime = Math.floor(Date.now() / 1000);
  
  if (tokenData.exp <= currentTime) {
    authStore.logout();
  }
};

export default getToken;