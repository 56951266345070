<template>
  <section class="coaches" id="all-coaches">
    <div class="container">
      <div class="coach-search" v-if="!coachType">
        <MagnifyingGlassIcon class="h-6 w-6"/>
        <!-- <SearchInput @search="handleSearch" :placeholder="Search by Name or Specialties (Career, Relationship, etc.)" /> -->
        <SearchInput @search="handleSearch" :coaches="coaches" :placeholder="$t('coaches.coachInput')" />
      </div>
      <div class="coach-list">
        <template v-if="coaches">
          <div v-for="(coach, index) in filteredCoaches || coaches" 
              :key="index" 
              :class="`${index > 7 ? coach.availability ? onlineCoachVisibilitiy : offlineCoachVisibilitiy : ''} `"> 
            <CoachCard :coach="coach" />
          </div>
        </template>
        <template v-if="!coachesLoaded">
          <div v-for="i in 8" :key="i" class="">
            <CoachCardLoader/>
          </div>
        </template>
      </div>
      
      <template v-if="coachesLoaded">
        <div class="text-center" v-if="displayLoadMoreButton">
          <a class="loader-btn btn btn-sm btn-primary" @click="manageCoachList">{{offlineCoachVisibilitiy!=='' ? $t('coaches.coachView') : $t('coaches.coachHide')}} {{onlineCoachVisibilitiy!=='' ? $t('coaches.coachMore') : $t('coaches.coachOfflineReaders')}}</a>
        </div>
      </template>

      <div v-if="error" class="bg-danger text-light p-3">
        Error: {{error}}
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import CoachCard from './CoachCard.vue';
import CoachCardLoader from './utilities/CoachCardLoader.vue';
import getCoaches from '../composables/getCoaches';
import Spinner from './utilities/Spinner.vue';
import { checkAvailability, shuffleList, setBusyCoaches, initializeLanguage, getInitializedLang } from '../composables/utilities';
import SearchInput from './SearchInput.vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
const coachesTemp = ref([]);
const coaches = ref([]);
const coachesLoaded = ref(false);
const onlineCoachVisibilitiy = ref("hide");
const offlineCoachVisibilitiy = ref("hide");

const { error, get } = getCoaches();
const lang = ref(''); // Track initialized language

onMounted(async () => {
  await initializeLanguage(); // Initialize the language
  lang.value = getInitializedLang(); // Set language after initialization

  coachesTemp.value = shuffleList(await get());
  coaches.value = coachesTemp.value;
  localiseSpecialties();
  coachesLoaded.value = coaches.value.length > 0;
  displayLoadMoreButton.value = true;
  
  // Set a counter for available coaches
  let availableCoachCtr = 0; 

  for (let i = 0; i < coaches.value.length; i++) {
    // Check availability
    coaches.value[i].availability = checkAvailability(coaches.value[i]);
    // Count available coaches
    if (coaches.value[i].availability) availableCoachCtr++; 
  }
  
  // Get 45% of the number of available coaches
  // This will make sure that number of busy coaches won't exceed
  // the number of available coaches
  let minBusyValue;
  if (lang.value === 'en') {
    minBusyValue = 0;
  } else {
    minBusyValue = Math.floor((availableCoachCtr / 100) * 45);
  }
  coaches.value = setBusyCoaches(coaches.value, minBusyValue, availableCoachCtr);
});

// Manipulate the Coach List based on Button Clicks
function manageCoachList() {
  if (onlineCoachVisibilitiy.value !== '') {
    onlineCoachVisibilitiy.value = '';
  } else if (onlineCoachVisibilitiy.value === '' && offlineCoachVisibilitiy.value !== '') {
    offlineCoachVisibilitiy.value = '';
  } else {
    offlineCoachVisibilitiy.value = 'hide';
  }
}

// Search Coaches
const displayLoadMoreButton = ref(true);
const searchText = ref('');
const handleSearch = (typed) => {
  if (typed === '') {
    coaches.value = coachesTemp.value;
    displayLoadMoreButton.value = true;
  } else {
    displayLoadMoreButton.value = false;
  }
  searchText.value = typed;
};

// Watch for changes in 'searchText' and update 'coaches'
watch(searchText, (newSearchText) => {
  const data = coachesTemp.value.filter(item => {
    const name = item.details.coachProfileName.toLowerCase();
    const specialties = item.specialties[lang.value].map(s => s.toLowerCase());
    return name.includes(newSearchText) || specialties.some(specialty => specialty.includes(newSearchText));
  });

  setTimeout(() => coaches.value = data, 500);
});

// Filter coaches by types based on readings coachType
const { coachType } = defineProps(['coachType']);
const filteredCoaches = computed(() => 
  coachType?.length ?
  coachesTemp.value.filter(coach => 
    coach.types.some(type => 
      coachType.some(cType => 
        type.includes(cType)
      )
    )
  )
  : false
);

// Localize specialties to enable searching in customer language
const localiseSpecialties = () => {
  coachesTemp.value.forEach(coach => {
    const localSpecialties = [];
    coach.specialties.forEach(specialty => {
      localSpecialties.push(t(`coaches.${specialty}`));
    });
    coach.specialties[lang.value] = localSpecialties;
  });
};
</script>
