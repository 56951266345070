import { useStorage } from "vue3-storage";
import { useAuthStore } from '@core/stores';

const authGuard = (to, from, next) => {
  const storage = useStorage();
  const authStore = useAuthStore();
  if (!storage.getStorageSync('customerJWT') && !authStore.isAuthenticated) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

export default authGuard