<template>
  <div class="home">
    <Hero/>
    <section class="sub-header" v-if="!stripeStore.subscription">
        <div class="sub-header-content mx-auto flex justify-center">
            <router-link 
            :to="{name:'Credits'}" 
            class=""><p class="text-white text-base font-semibold">{{ $t('header.subHeader') }}</p>
            </router-link>
        </div>
    </section>
    <Explore/>
    <CoachList/>
    <Features/>
    <HowItWorks/>
    <About/>
    <Articles/>
    <Testimonials/>
    <CallToAction/>
  </div>
</template>

<script setup>
import { defineAsyncComponent, onMounted } from 'vue';
import { useStorage } from "vue3-storage";
import { useRoute } from 'vue-router';
import { useStripeStore } from '@core/stores';
import Hero from '@/components/Hero.vue';

// lazy loaded components after Hero
const Explore = defineAsyncComponent(()=> import('@/components/Explore.vue'))
const CoachList = defineAsyncComponent(()=> import('@core/components/CoachList.vue'))
const Testimonials = defineAsyncComponent(()=> import('@/components/Testimonials.vue'))
const Articles = defineAsyncComponent(()=> import('@/components/Articles.vue'))
const About = defineAsyncComponent(()=> import('@/components/About.vue'))
const HowItWorks = defineAsyncComponent(()=> import('@/components/HowItWorks.vue'))
const Features = defineAsyncComponent(()=> import('@/components/Features.vue'))
const CallToAction = defineAsyncComponent(()=> import('@/components/CallToAction.vue'))

const storage = useStorage();
const route = useRoute();
const stripeStore = useStripeStore();

onMounted(async () => {
  /**
    * Klaviyo Metrics
  */

  // Get customer details to be identify on klaviyo
  const customerStorage = await storage.getStorageSync('customerInfo');
  
  if (window.klaviyo && customerStorage) {
    
    let profileId = customerStorage.profile._id.split('|')[1];
    
    // Klaviyo Identify Properties - Mandatory
    const identifyProperties = {
      'email' : profileId,
      'first_name' : customerStorage.profile.firstName,
      'last_name' : customerStorage.profile.lastName,
    }

    // Callback function
    const onIdentifyCompleteCallback = () =>  {
      window.klaviyo.push(['track', 'Viewed Product', route.path]);
    }
    
    // Identify user then send custom event for the identified user
    window.klaviyo.identify(identifyProperties, onIdentifyCompleteCallback);

  } else {
      console.error('Klaviyo script not loaded');
  }
})

</script>
