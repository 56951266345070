<template>
    <section class="notfound-container">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-3xl dark:text-white">
                {{$t("404.h1")}}
            </h1>
            <p class="mt-3 text-sm font-light text-gray-500 dark:text-gray-400">{{$t("404.p")}}</p>
            <img src="../assets/img/404.png" class=""/>
            
            <RouterLink class="
            backtohome
            mt-4
            px-5 
            py-2.5 
            rounded-sm 
            font-medium 
            text-sm 
            text-center 
            text-white 
            bg-primary-400 
            hover:bg-primary-500 
            dark:bg-primary-400
            dark:hover:bg-primary-500 
            dark:focus:ring-primary-600" to="/">{{$t("404.btn")}}</RouterLink>
    </div>
  </section>
</template>
  
<style lang="scss">

</style>
  