// pageTitles.js

import { initializeLanguage, getInitializedLang } from '@core/composables/utilities';

// Define page titles
const pageTitles = {
  en: {
    "advice": "Articles",
    "horoscope": "Daily Horoscope",
    "readings": "Readings"
  },
  nl: {
    "advice": "Artikelen",
    "horoscope": "Dagelijkse Horoscoop",
    "readings": "Lezingen"
  },
};

// Initialize language asynchronously
(async () => {
  await initializeLanguage(); // Ensure language is initialized
})();

export default function getPageTitles() {
  return pageTitles[getInitializedLang()]; // Use initialized language
}
