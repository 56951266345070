import { useGtm } from '@gtm-support/vue-gtm';

const track = {
    signUp(){
        useGtm().trackEvent({
            event: 'sign_up'
        })
    },

    beginCheckout(amount, id){
        useGtm().trackEvent({
            event: 'begin_checkout',
            category: 'purchase',
            label: 'Purchase Attempt',
            transaction_id: id,
            value: amount
        })
    },
    
    purchase(amount, id){
        useGtm().trackEvent({
            event: 'purchase',
            category: 'ecommerce',
            label: 'Successful Purchase',
            transaction_id: id,
            value: amount
        })
    },

}


export default track
  