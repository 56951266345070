// utilities.js

/**
 * Class that contains functions that 
 * can be used across the app
*/ 

import domainName from '@core/composables/domainName'

/**
 * Create a full image path
 * @param unprocess (string) | Generate Filename from this string
 * @param path (string) | Folder directory where the images are located
 * @param ext (string) | file extension of the images - limited to one extension only
 * @param find (regExp) 
 * @param replace (character) 
 */
const generateImageURL = (unprocess, path, ext, find, replace) => {
  var fileName = processString(unprocess, find, replace);
  return path + fileName + ext;
}

/**
* Converts a string into lowercase and replace space with underscore
* @param proccesMe (string) | Generate Filename from this string
* @param fromThis (regExp) | Generate Filename from this string
* @param toThis (character) | Generate Filename from this string
*/
const processString = (proccesMe, fromThis, toThis) => {
  return proccesMe.toLowerCase().replace(fromThis, toThis);
}

/**
 * Converts a UTC timestamp (either ISO 8601 format or Unix timestamp) to a readable local date.
 * For Unix timestamps (detected as string of digits), converts to milliseconds for Date object creation.
 * Parses ISO 8601 strings directly to create the Date object.
 * @param utcTimestamp (string | number) - UTC timestamp in ISO 8601 format or Unix timestamp.
 * @returns A Date object representing the local date.
 */
const toLocalDate = (utcTimestamp) => {
  let utcParsed;
  if (/^\d+$/.test(utcTimestamp)) {
      utcParsed = parseInt(utcTimestamp) * 1000;
  } else {
      utcParsed = Date.parse(utcTimestamp);
  }
  return new Date(utcParsed);
}

/**
* Convert UTC time stamp to a readable local date format
* e.g. Jun 16 2023
* @param utcTimestamp (string) | raw utc time stamp
*/
const toLocalDateShort = (utcTimestamp) => {
  const localDate = toLocalDate(utcTimestamp);
  const lang = getInitializedLang(); // Use the initialized language
  const localDateShort = localDate.toLocaleString(`${lang}-${getGeo()}`, { 
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
  return localDateShort;
};

/**
 * Converts an ISO 8601 datetime or Unix timestamp to local time in 12-hour format (e.g., '09:48 AM').
 * Handles both ISO strings (appending 'Z' for UTC) and Unix timestamps (converted to milliseconds).
 * @param utcTime (string | number) - ISO 8601 datetime or Unix timestamp.
 * @returns Formatted local time string.
 */
const toLocalTime = (utcTime) => {
  let date;

  if (/^\d+$/.test(utcTime)) {
    date = new Date(parseInt(utcTime) * 1000);
  } else {
    date = new Date(utcTime + 'Z');
  }

  const lang = getInitializedLang(); // Use the initialized language
  return date.toLocaleTimeString(`${lang}-${getGeo()}`, { hour: '2-digit', minute: '2-digit' });
};

/**
* Get the date today and the current hour in 24 format
*/
const getDateHour = () => {
  const toDate = new Date();
  return {"Date": toDate.getDate(), "Hour": toDate.getHours()};
}

/**
* Check if a Coach is online or not
* @param props | vue props object
*/
const checkAvailability = (coach) => {
  const coachShifts = coach.sdata; 
  const toDate = getDateHour(); 
  const todayShift = coachShifts[Object.keys(coachShifts).find((day)=> day == (toDate.Date === 31 ? toDate.Date-2 : toDate.Date-1))]; 
  
  const shiftStart = parseInt(todayShift);
  const shiftEnd = parseInt(todayShift) + 12; 
  var currentHour = parseInt(toDate.Hour);
  var isAvailable = (currentHour >= shiftStart && currentHour<= shiftEnd);

  if(shiftStart > 11) {
      isAvailable = (currentHour >= shiftStart); 
    if(!isAvailable){
      if(shiftStart >= 12){
        if((currentHour + 12) <= shiftStart){
          isAvailable = (currentHour <= shiftEnd);
        } 
      }
    }
  }

  return isAvailable;
}  

/**
* Shuffle an array
* @param arr | array to be shuffled
*/
const shuffleList = (arr) => {
  let currentIndex = arr.length,  randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]];
  }

  return arr;
}

/** 
 * Appoint Busy Coaches
 * @param allCoaches | Object 
 * @param minBusyValue | Int 
 * @param availableCoachesCount | Int
*/
const setBusyCoaches = (allCoaches, minBusyValue, availableCoachesCount) => {
  allCoaches = allCoaches.sort((a, b) => Number(b.availability) - Number(a.availability));

  for(var i=minBusyValue; i>0; i--) {
    allCoaches[availableCoachesCount-1].isBusy = true;
    availableCoachesCount--;
  }

  return allCoaches;
}

/**
* Capitalize each word in a string
* @param str | string to be capitalized
*/
const capitalizeString = (str) => {
  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(" ");
}

/**
* Create an script tag and append to the <head>
* @param source | string 
*/
const createAppendScript = (source) => {
  const scriptEl = document.createElement("script");
  scriptEl.setAttribute("src", source);
  document.head.appendChild(scriptEl);
}

/**
* Get all URL query strings
*/
const getQryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams;
}

const formatUsername = (email) => {
  let userName;
  let formatEmail = email.toLowerCase();

  if (domainName.includes('uat') || domainName.includes('staging') || domainName.includes('localhost')){
      userName = `thelmcoach.com|${formatEmail}`; 
  }else{
      userName = `${removeWWW(domainName)}|${formatEmail}`; 
  }
  return userName;
}

const formatBirthdate = (dateStr) => {
  let dateArr;
  
  if(dateStr.indexOf('/') >= 0){
      dateArr = dateStr.split("/").reverse().join('-');
  }
  else if(dateStr.indexOf('-') >= 0) {
      dateArr = dateStr.split("-").reverse().join('/');
  }
  return dateArr
}

const decryptThis = (str) => {
  return JSON.parse(atob(str.split('.')[1]));
}

// Remove WWW in domain to match the domain config in AWS
const removeWWW = (domain) => {
  if(!domain.includes('www')) return domain;
  
  let domainParts = domain.split('.');
  let cleanedDomain = domainParts[1] + '.' + domainParts[2];

  return cleanedDomain;
}

/** 
 * Format display price by country
 * @param price | string 
 * @param country | string 
 * @param currency | string
*/
const formatPrice = (price, country, currency) => {
  const priceDec= price.split('.');
  let displayPrice;
  switch (country) {
    case 'NL':
      displayPrice = `&euro; ${priceDec[0]}<sup>,${priceDec[1]}</sup>`;
      break;
    default :
      displayPrice = `$${priceDec[0]}<sup>.${priceDec[1]}</sup>`;
      break;
  }
  return displayPrice;
}

/** 
 * Format trial display price by country
 * @param price | string 
 * @param country | string 
 * @param currency | string
*/
const formatTrialPrice = (price, country, currency) => {
  const priceDec= price.split('.');
  let displayPrice;
  switch (country) {
    case 'NL':
      displayPrice = `<b>&euro; ${priceDec[0]},${priceDec[1]}</b>`;
      break;
    default :
      displayPrice = `$${priceDec[0]}.${priceDec[1]}`;
      break;
  }
  return displayPrice;
}

/**
 * Convert recurring date format
 * @param {*} expiryDate 
 * @returns date format: xx/xx/xxxx
 */
const convertRecurringDate = (expiryDate) => {
  const currentPeriodEnd = new Date(expiryDate * 1000);
  return currentPeriodEnd.toLocaleDateString();
}

let initializedLang = null;

async function initializeLanguage() {
  if (!initializedLang) {
    initializedLang = await getCustomerLang();
  }
  return initializedLang;
}

function getInitializedLang() {
  if (!initializedLang) {
    throw new Error("Language has not been initialized yet. Please call initializeLanguage first.");
  }
  return initializedLang;
}

async function getCustomerLang() {
  const country = await getCountryFromHeader();

  const countryLangMap = {
    nl: 'nl',
    be: 'nl',
    sr: 'nl',
    aw: 'nl',
    cw: 'nl',
    sx: 'nl',
    // Add more countries and their respective languages here
  };

  const lang = countryLangMap[country] || 'en';

  document.documentElement.lang = lang; // Set document language

  sessionStorage.setItem("X-User-Language", lang);

  return lang;
}

// Function to get 'X-Ip-Country' value from response headers
async function getCountryFromHeader() {
  // Check if the country is already stored in sessionStorage
  let country = sessionStorage.getItem("X-Ip-Country");

  if (country) {
    return country; // Return the stored value if it exists
  }

  try {
    const response = await fetch('/favicon.ico', { method: 'HEAD' });
    country = response.headers.get('X-Ip-Country');

    // Use 'us' as default if country is not defined
    country = country ? country.toLowerCase() : 'us';

    // Store the country in sessionStorage for future use
    sessionStorage.setItem("X-Ip-Country", country);

    return country;
  } catch (error) {
    console.error('Error fetching X-Ip-Country header:', error);
    return 'us'; // Default to 'us' on error
  }
}

const getCurrentGeo = async () => {
  let currentGeo = sessionStorage.getItem("X-Ip-Country");
  currentGeo = currentGeo ? currentGeo.toLowerCase() : 'us';
  return currentGeo;
}

/**
 * Get geo for localization of dates and time and currency
 * currently taken from domain extension
 * add logic here if in the future we'll add geo as param in url
 */
const getGeo = () => {
  let domainExtension = domainName.split('.').slice(-1)[0];
  let geo;

  switch(domainExtension) {
    case 'nl':
    case 'fr':
    case 'uk':
    case 'de':
      geo = domainExtension;
      break;
    default:
      geo = 'nl';
      break;
  }
  
  return geo.toUpperCase();
}

// Get cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export { 
  generateImageURL,
  getDateHour,
  checkAvailability,
  setBusyCoaches,
  toLocalDate,
  toLocalDateShort,
  toLocalTime,
  shuffleList,
  capitalizeString,
  createAppendScript,
  getQryParams,
  formatUsername,
  formatBirthdate,
  decryptThis,
  removeWWW,
  formatPrice,
  formatTrialPrice,
  convertRecurringDate,
  initializeLanguage,
  getInitializedLang,
  getCurrentGeo,
  getGeo,
  getCookie
}
